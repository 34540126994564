.piecesContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.piecesContainer-lg {
  margin-top: 30px;
  justify-content: center;
}

.piecesContainer-sm {
  justify-content: flex-start;
}

.piece {
  transition: 0.3s background-color;
  box-sizing: border-box;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.piece-sm {
  width: 60px;
  height: 60px;
}

.piece-lg {
  width: 100px;
  height: 100px;
}

.block {
}

.block-sm {
  width: 10px;
  height: 10px;
}

.block-lg {
  width: 18px;
  height: 18px;
}
