.game_button-root {
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: transform 0.3s, filter 0.3s;
  position: relative;
  text-align: center;
  animation: 0.5s game_button-root-appear;
}

.game_button-root:active {
  transform: scale(0.8);
  filter: brightness(1.1);
}

@keyframes game_button-root-appear {
  0% {
    transform: scale(0);
    filter: brightness(0);
  }
  50% {
    transform: scale(1.1);
    filter: brightness(2);
  }
  100% {
    transform: scale(1);
    filter: brightness(1);
  }
}

.game_button-root_disabled {
  transform: scale(0.7);
  filter: brightness(0.5);
}

.game_button-size_lg {
  width: 607px;
  height: 120px;
}

.game_button-size_md {
  width: 450px;
  height: 90px;
}

.game_button-size_sm {
  width: 300px;
  height: 60px;
}

.game_button-0 {
  background-image: url('./red.webp');
}

.game_button-1 {
  background-image: url('./purple.webp');
}

.game_button-2 {
  background-image: url('./yellow.webp');
}

.game_button-3 {
  background-image: url('./green.webp');
}
