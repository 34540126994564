.root {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: black;
  flex-direction: column;
}

.dPad {
  margin-top: calc(var(--vh, 1vh) * -10);
  margin-bottom: calc(var(--vh, 1vh) * 6);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button {
  white-space: pre;
  font-size: calc(var(--vh, 1vh) * 4);
  text-align: center;
  width: 80vw;
  margin-top: calc(var(--vh, 1vh) * 4);
  transition: 0.3s;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button:active {
  filter: brightness(1.2);
  transform: scale(0.8);
}

.buttonOn {
  height: calc(var(--vh, 1vh) * 10);
  opacity: 1;
  z-index: 0;
}

.buttonOff {
  z-index: 0;
  height: calc(var(--vh, 1vh) * 6);
  opacity: 0.5;
}

.dPadArrow {
  background: white;
  font-size: calc(var(--vh, 1vh) * 4);
  text-align: center;
  width: calc(var(--vh, 1vh) * 14);
  height: calc(var(--vh, 1vh) * 14);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s transform, 0.3s filter;
}

.dPadArrow:active {
  filter: brightness(1.2);
  transform: scale(0.8);
}

.dPadArrowIcon {
  background-image: url('./arrow.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: calc(var(--vh, 1vh) * 6);
  height: calc(var(--vh, 1vh) * 6);
}

.dPadMirrorIcon {
  background-image: url('./mirror.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: calc(var(--vh, 1vh) * 8);
  height: calc(var(--vh, 1vh) * 8);
}

.dPadRotateIcon {
  background-image: url('./rotate.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: calc(var(--vh, 1vh) * 6);
  height: calc(var(--vh, 1vh) * 6);
}

.drawerArrow {
  background: white;
  font-size: calc(var(--vh, 1vh) * 4);
  text-align: center;
  width: calc(var(--vh, 1vh) * 10);
  height: calc(var(--vh, 1vh) * 10);
  position: absolute;
  top: calc(var(--vh, 1vh) * -10);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
}

.drawerArrow:active {
  filter: brightness(1.2);
  transform: scale(0.8);
}

.arrowRight {
  border-top-right-radius: calc(var(--vh, 1vh) * 1);
  border-bottom-right-radius: calc(var(--vh, 1vh) * 1.2);
}

.arrowUp {
  border-top-right-radius: calc(var(--vh, 1vh) * 1.2);
  border-top-left-radius: calc(var(--vh, 1vh) * 1.2);
}

.arrowDown {
  border-bottom-right-radius: calc(var(--vh, 1vh) * 1.2);
  border-bottom-left-radius: calc(var(--vh, 1vh) * 1.2);
}

.arrowLeft {
  border-top-left-radius: calc(var(--vh, 1vh) * 1.2);
  border-bottom-left-radius: calc(var(--vh, 1vh) * 1.2);
}

.mirror {
  margin-top: calc(var(--vh, 1vh) * 4);
  border-radius: calc(var(--vh, 1vh) * 1.2);
  width: 60vw;
}

.mirrorAnimationContainer {
  transform: scale(0);
  animation: mirror-enter 0.6s 0.5s forwards, mirror-shine 4s 2s infinite;
}

@keyframes mirror-shine {
  0% {
  }
  10% {
    transform: scale(1.1);
  }
  20% {
    transform: scale(1);
  }
  100% {
  }
}

@keyframes mirror-enter {
  0% {
  }
  50% {
    transform: scale(1.2);
    filter: brightness(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.leftRight {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.drawerBackground {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
  opacity: 0;
  transition: opacity 0.6s, z-index 0.6s;
  z-index: -1;
}

.drawerBackgroundOpen {
  opacity: 1;
  z-index: 100;
}

.drawer {
  bottom: 0;
  position: absolute;
  width: 100%;
  height: calc(var(--vh, 1vh) * 80);
  transform: translateY(calc(var(--vh, 1vh) * 80));
  transition: transform 0.5s cubic-bezier(0.67, 0.17, 0.22, 1.27);
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  z-index: 1000;
}

.drawerOpen {
  transform: translateY(0);
}

.drawerClose {
  transition-delay: 0.1s;
}

.drawerBody {
  overflow: scroll;
  display: flex;
}
